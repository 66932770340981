@import 'selectize';
.international {
	#wrapper {
		text-align: center;
	}
	.mainLogo, .hamburger, .int-menu, main {
		position: relative;
		z-index: 2;
	}
	.mainLogo {
		width: 220px;
		height: auto;
		margin: 2rem auto;
	}
	.int-menu {
		display: inline-block;
		margin: 1rem 0;
		border-top: 1px solid $gold;
		border-bottom: 1px solid $gold;

		ul {
			display: block;
			width: 100%;
			text-align: center;
			text-transform: uppercase;
			font-size: 0;

			li {
				@include TYPCOND(1rem, normal, 600, 1.3);
				display: inline-block;
				vertical-align: top;

				&.current_page_item a {
					border-color: $gold;
				}
			}
			a {
				display: block;
				padding: 12px 1rem 9px;
				border-bottom: 3px solid transparent;
				@include fontGold;
				@include transition(border-color 250ms ease-in-out);

				&:hover {
					text-decoration: none;
					border-color: $gold;
				}
			}
		}
	}
	main {
		display: block;
		width: 100%;
		margin: 2rem 0;
		padding: 1rem 1.5rem;
		background-color: rgba(255, 255, 255, .95);
		@include border-top-radius(5px);
		@include border-bottom-radius(5px);

		.elementor-widget-heading {
			h1, h2, h3, h4, h5, h6 {
				&.elementor-heading-title {
					font-family: $title;
					color: $d-gray;
				}
			}
		}
		* {
			font-size: 16px;
		}
		.elementor-text-editor,
		.elementor-tabs {
			h1, h2, h3, h4, h5, h6 {
				font-family: $title;
				color: $d-gray;
				font-weight: 600;
				margin-bottom: 1rem;
			}
			h1 {
				font-size: 34px;
			}
			h2 {
				font-size: 29px;
			}
			h3 {
				font-size: 24px;
			}
			h4 {
				font-size: 20px;
			}
			h5 {
				font-size: 18px;
			}
			h6 {
				font-size: 16px;
			}
			p {
				margin-bottom: 1rem;
				color: $d-gray;
				@include TYPCOND(16px, normal, 300, 140%);
			}
			a {
				color: $gold;
			}
			em, i {
				font-style: italic;
			}
			strong, b {
				font-weight: 600;
			}
			ol, ul {
				font-family: $typcond;
				font-weight: 300;
				line-height: 140%;
				padding-left: 1rem;

				li {
					display: list-item;
					margin: .3rem 0 0.5rem;

					ol, ul {
						padding-left: 1.5rem;
						margin-bottom: 0.5rem;
					}
				}
				// span {
				// 	position: relative;
				// 	left: 8px;
				// }
			}
			ol {
				list-style-type: decimal;
			}
			ul {
				list-style-type: disc;
			}
		}
		.elementor-widget-icon-list .elementor-icon-list-text {
			@include TYPCOND(16px, normal, 300, 140%);
		}
		.elementor-tab-content {
			background-color: #fff;
		}
		.elementor-tab-title {
			color: $d-gray;

			&.active {
				background-color: rgba(255, 255, 255, 0.3);
			}
		}
		/** Gravity Form */
		.gform_wrapper {
			font-family: $typ;

			&.gf_browser_chrome .gform_body {
				width: 100%;
			}

			&, & * {
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
			}

			ul.gform_fields li.gfield.gsection {
				padding: 1rem;
				margin: 2rem 0 1rem;
				background-color: $d-gray;
				color: #fff;
				border: none;
			}

			input[type=text],input[type=email], input[type=tel], input[type=password],
			textarea {
				border: 1px solid $l-gray;
				@include border-top-radius(3px);
				@include border-bottom-radius(3px);
				color: $d-gray;

				&:focus {
					outline: 0;
					box-shadow: none;
				}
			}
			input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
				padding: 8px 10px;
			}
			input[type=radio] {
				-webkit-appearance: radio;
			}
			input[type=checkbox] {
				-webkit-appearance: checkbox;
			}
			.nice-select {
				margin-bottom: 15px;
				float: none;
				&+label {
					margin-top: -10px !important;
				}
			}
			.field_sublabel_below .ginput_complex.ginput_container label, .field_sublabel_below div[class*=gfield_time_].ginput_container label,
			.field_sublabel_below div[class*=gfield_date_].ginput_container label {
				margin-top: 5px;
				font-style: italic;
				color: $d-gray;
				font-size: 0.8rem;
			}
			.gfield_description {
				margin-top: 1rem;
				font-style: italic;
				color: $d-gray;
				font-size: 0.8rem;
				padding: 0.4rem 1rem;
				border-left: 4px solid $d-gray;
				line-height: 1.3;
				background-color: rgba(0,0,0,0.05);
			}
			.gfield_html {
				font-style: italic;
			}
			.ginput_complex span.ginput_cardextras {
				overflow: visible;
			}
			.ginput_cardinfo_left {
				margin-right: 20px;
			}
			span.ginput_total {
				font-weight: bold;
			}
			.gform_footer input.button, 
			.gform_footer input[type=submit], 
			.gform_page_footer input.button, 
			.gform_page_footer input[type=submit] {
				color: #fff;
				background-color: $purple;
				border: 0;
				@include border-top-radius(3px);
				@include border-bottom-radius(3px);
				padding: 0.5rem 1rem;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 14px;
				cursor: pointer;
				@include transition(background-color 250ms ease-in-out);

				&:hover {
					background-color: darken($purple, 5%);
				}
			}

			.gfield_error .gfield_label,
			.validation_message {
				color: $red;
			}

			li.gfield.gfield_error, 
			li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
				border-color: $red;
				background-color: lighten($red, 40%);
			}
		}
		.gform_confirmation_message {
			@include TYPCOND(16px, normal, 300, 140%);
			display: block;
			padding: 1rem 1.5rem;
			color: #fff;
			background-color: #15bf80;
		}
	}
	footer {
		display: block;

		.copyright {
			text-align: center;
			color: #ccc;
			@include TYPCOND(14px, normal, 300, 140%);
		}
	}
}