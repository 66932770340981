$typcond: "proxima-nova-condensed", Arial, Helvetica, sans-serif;
@mixin TYPCOND ($size, $style:"", $weight:"", $line-height:""){
	font-family: $typcond;
	font-size: $size;
	line-height: #{$line-height};
	font-weight: #{$weight};
	font-style: #{$style};
}
$typ: "proxima-nova", Arial, Helvetica, sans-serif;
@mixin TYP ($size, $style:"", $weight:"", $line-height:""){
	font-family: $typ;
	font-size: $size;
	line-height: #{$line-height};
	font-weight: #{$weight};
	font-style: #{$style};
}
$title: "dapifer", Palatino, serif;
@mixin TITLE ($size, $style:"", $weight:"", $line-height:""){
	font-family: $title;
	font-size: $size;
	line-height: #{$line-height};
	font-weight: #{$weight};
	font-style: #{$style};
}
@mixin MONO ($size, $style:"", $weight:"", $line-height:""){
	font-family: "Lucida Console", "Courier New", Monaco, Courier, monospace;
	font-size: $size;
	line-height: #{$line-height};
	font-weight: #{$weight};
	font-style: #{$style};
}
@mixin box-shadow($x-offset, $y-offset, $blur, $spread, $color) {
	-webkit-box-shadow: $x-offset $y-offset $blur $spread $color;
	-moz-box-shadow: $x-offset $y-offset $blur $spread $color;
	box-shadow: $x-offset $y-offset $blur $spread $color;
}

$gold: #EBBC39;
$tan: #DDD3BC;
$purple: #B12ECF;
$l-gray: #e6e6e6;
$d-gray: #514e49;
$red: #f31919;

@mixin fontGold {
	color: $gold;
}
@mixin fontTan {
	color: $tan;
}
@mixin fontPurple {
	color: $purple;
}
@mixin fontLightGray {
	color: $l-gray;
}
@mixin fontDarkGray {
	color: $d-gray;
}