/*   
Theme Name: YAMA Theme
Theme URI: http://youngartistsmusicacademy.org
Description: Yama custom Theme
Author: Kirill Miniaev
Author URI: http://naaft.com
Version: 1
*/
@import 'reset';
@import 'bourbon';
@import 'mixins';
@import 'selectize';

/*:::::: TYPOGRAPHY ::::::*/
a:link, a:visited {
	text-decoration: none;
}
a:hover, a:active {
	text-decoration: underline;
}
a.link_no_underline:link, a.link_no_underline:visited {
	text-decoration: none;
}

/*:::::: HIGH LEVEL ELTS ::::::*/
html, body {
	width: 100%;
	height: 100%;
}
*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.respib, .respb {
	display: none !important;
}

input, textarea {
	appearance: none;
}

/*:::::: BODY ELTS ::::::*/
body {
	background-color: #1a1a1a;
}
#wrapper {
	font-size: 0;
	display: block;
	width: 960px;
	margin: 0 auto;
	padding-bottom: 30px;
}
#leftColumn, #rightColumn {
	display: inline-block;
	padding-top: 30px;
}
#leftColumn {
	font-size: 16px;
	width: 16.875%;
	margin-right: 2.5%;
	vertical-align: top;
}
.mainLogo {
	display: block;
	width: 100%;
	vertical-align: top;

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}
.moduleHeader, .tribe-events-list-widget h2 {
	display: block;
	width: 100%;
	height: 24px;
	padding-left: 8px;
	border-bottom: 1px solid rgba(255, 255, 255, .3);
	background-color: rgba(255, 255, 255, .1);
}
.moduleHeader span, .tribe-events-list-widget h2 {
	text-transform: uppercase;
	@include TYPCOND(16px, normal, 400, 24px);
	@include fontTan;
}
.moduleBottomBorder {
	display: block;
	width: 100%;
	height: 6px;
	margin-top: 10px;
	border-top: 1px solid rgba(255, 255, 255, .3);
	background-color: rgba(255, 255, 255, .1);
}
.module .moduleBottomBorder {
	margin-top: 0;
}
#latestNewsModule {
	margin-top: 26px;
}
.latestNews-Event {
	padding: 6px 8px;
	border-bottom: 1px solid rgba(255, 255, 255, .3);
}
.latestNews-Event:last-of-type {
	border-bottom: 0;
}
.latestNews-Event-Title {
	@include TYP(15px, normal, 400, 18px);
	@include fontLightGray;
}
.latestNews-Event-Date {
	display: block;
	margin-top: 5px;
	text-transform: uppercase;
	@include TYP(12px, normal, 400, 18px);
	@include fontPurple;
}
.tribe-events-list-widget {
	margin-top: 34px;

	.tribe-list-widget {
		list-style: none;

		li {
			line-height: normal;
			margin: 0;
			padding: 6px 8px;
			border-bottom: 1px solid rgba(255, 255, 255, .3);

			a {
				@include TYP(15px, normal, 400, 18px);
				@include fontLightGray;
			}
			.tribe-event-duration {
				display: block;
				margin-top: 5px;
				text-transform: uppercase;
				@include TYP(12px, normal, 400, 14px);
				@include fontPurple;
			}
		}
	}
	.tribe-events-widget-link {
		margin-top: 10px;
		text-align: center;

		a {
			display: inline-block;
			padding: 6px 12px;
			text-decoration: none;
			text-transform: uppercase;
			color: #fff;
			background-color: $gold;
			@include border-top-radius(3px);
			@include border-bottom-radius(3px);
			@include TYPCOND(16px, normal, 400, 100%);
		}
	}
	&::after {
		display: block;
		width: 100%;
		height: 6px;
		margin-top: 10px;
		content: '';
		border-top: 1px solid rgba(255, 255, 255, .3);
		background-color: rgba(255, 255, 255, .1);
	}
}
#rightColumn {
	font-size: 16px;
	width: 80.625%;
	vertical-align: top;
}
#watermark {
	position: fixed;
	z-index: 0;
	top: 0;
	left: 50%;
	display: block;
	width: 911px;
	height: 850px;
	margin-left: -430px;
	background-image: url(assets/img/icons/backgroundSplash.svg);
	background-repeat: no-repeat;
}
#leftColumn, #tagline, #mainContent, #mainFooter {
	position: relative;
	z-index: 2;
}
#tagLine {
	display: block;
	width: 100%;
	height: 27px;
	margin-bottom: 18px;
	text-indent: -9999px;
	background-image: url(assets/img/icons/tagLine.svg);
	background-repeat: no-repeat;
	background-position: right center;
}
.hamburger {
	font-size: 0;
	position: relative;
	z-index: 9999;
	top: 0;
	right: 0;
	display: none;
	float: right;
	width: 30px;
	height: 38px;
	color: transparent;
	@include border-top-radius(5px);
	@include border-bottom-radius(5px);
	@include transition(left 250ms ease-in-out, top 250ms ease-in-out, right 250ms ease-in-out);

	.lines {
		position: relative;
		top: 16px;
		display: block;
		width: 30px;
		height: 4px;
		background: $gold;
		@include transition(all 250ms ease-in-out);

		&::before, &::after {
			position: absolute;
			right: 0;
			display: block;
			width: 26px;
			height: 4px;
			content: '';
			background: $gold;
			@include transition(all 250ms ease-in-out);
			@include transform-origin(0 center);
		}
		&::before {
			top: 8px;
		}
		&::after {
			top: -8px;
		}
	}
	&.active .lines {
		background: transparent;

		&::before, &::after {
			top: 0;
			left: 1px;
			width: 30px;
			background: $gold;
			@include transform-origin(50% 50%);
		}
		&::before {
			@include transform(rotate3d(0, 0, 1, 45deg));
		}
		&::after {
			@include transform(rotate3d(0, 0, 1, -45deg));
		}
	}
}
.mainNav {
	font-size: 0;
	z-index: 4;
	margin-bottom: 24px;

	.menu {
		position: relative;
	}
}
.mainNav ul li {
	position: relative;
	display: inline-block;
	margin-right: 35px;
	text-align: left;
	vertical-align: top;

	&.current-menu-item {
		& > a {
			width: auto;
			border-bottom: 2px solid $gold;
		}
	}
	&:last-of-type {
		margin-right: 0;
	}
	a {
		display: inline-block;
		padding-bottom: 3px;
		text-align: left;
		text-transform: uppercase;
		@include TYPCOND(17px, normal, 500, 20px);
		@include fontGold;

		&:hover {
			text-decoration: none;
		}
	}
	&:hover {
		ul.sub-menu {
			display: block;
		}
	}
}
.mainNav ul li ul.sub-menu {
	position: absolute;
	z-index: 9999;
	top: 22px;
	left: -2px;
	display: none;
	text-align: left;
	background-color: #1a1a1a;
	@include border-top-radius(3px);
	@include border-bottom-radius(3px);
	@include box-shadow(0, 0, 10px, 0, rgba(0, 0, 0, .3));

	li {
		display: block;
		width: 100%;
		text-align: left;
		white-space: nowrap;

		&:first-of-type a {
			@include border-top-radius(3px);
		}
		&:last-of-type a {
			@include border-top-radius(3px);
		}
		&.current-menu-item {
			& > a {
				border-bottom: 0;
			}
		}
		a {
			width: 100%;
			height: 100%;
			padding: 10px 20px;
			text-align: left;
			text-transform: none;
			@include TYPCOND(15px, normal, 400, 16px);
			@include fontTan;

			&:hover {
				background-color: rgba(255, 255, 255, .1);
			}
		}
	}
}
.mainNav ul .mainNav-Opus {
	top: -4px;
	width: 48px;

	a {
		width: 48px;
		text-indent: -9999px;
		background-image: url(assets/img/icons/opusTag.svg);
		background-repeat: no-repeat;
		background-position: right center;
	}
}
#mainContent {
	display: block;
	width: 100%;
	margin-bottom: 20px;
	border-bottom: 9px solid #fad948;
}
#landingSlideshow {
	display: block;
	width: 100%;
	height: 490px;
	background-color: #fcfcfc;
}
#mainFooter {
	font-size: 0;
	display: block;
}
.footerBanner {
	display: inline-block;
	width: 280px;
	margin-right: 3.10077519379845%;
}
#footerLinks {
	display: inline-block;
	width: 21.44702842377261%;
	margin: 0;
	vertical-align: top;
}
.subfooter {
	display: block;
	width: 100%;
	height: auto;

	#menu-social {
		display: block;
		width: 100%;
		text-align: right;

		li {
			display: inline-block;
			width: 18px;
			height: 18px;
			margin-right: 8px;
			vertical-align: top;

			&:last-of-type {
				margin-right: 0;
			}
			a {
				display: block;
				width: 100%;
				height: 100%;
			}
			&.tw a {
				background-image: url(assets/img/icons/twitterFooter.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100% 100%;
			}
			&.yt a {
				background-image: url(assets/img/icons/youtubeFooter.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100% 100%;
			}
			&.fb a {
				background-image: url(assets/img/icons/facebookFooter.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100% 100%;
			}
		}
	}
}
#copyright {
	display: block;
	margin-top: 10px;
	text-align: right;
	@include TYPCOND(12px, normal, 400, 120%);
	@include fontTan;

	a {
		@include fontTan;
	}
}
#colophon {
	display: block;
	margin-top: 10px;
	text-align: right;
	text-transform: uppercase;
	color: #919191;
	@include TYPCOND(10px, italic, 400, 120%);
}

/**ABOUT PAGE**/
#mainContentBody {
	font-size: 0;
	background-color: #fcfcfc;
}
#attractImg img, .no-header-img {
	@include border-top-radius(5px);
}
#attractImg img {
	display: block;
	width: 100%;
	height: auto;
}
.roundTops {
	@include border-top-radius(5px);
}
#mainContentBody {
	h1 {
		margin: 6px 0 15px;
		@include TITLE(36px, normal, 600, 38px);
		@include fontDarkGray;
	}
	h2 {
		margin: 6px 0 15px;
		@include TITLE(24px, normal, 600, 32px);
		@include fontDarkGray;
	}
	h3 {
		margin: 6px 0 15px;
		@include TITLE(20px, normal, 600, 28px);
		@include fontDarkGray;
	}
	h4 {
		margin: 6px 0 15px;
		@include TITLE(18px, normal, 600, 24px);
		@include fontDarkGray;
	}
	h5 {
		margin: 6px 0 15px;
		@include TITLE(16px, normal, 600, 20px);
		@include fontDarkGray;
	}
	h6 {
		margin: 6px 0 15px;
		@include TITLE(14px, normal, 600, 18px);
		@include fontDarkGray;
	}
}
#mainContentBody-LC, #mainContentBody-RC {
	display: inline-block;
	vertical-align: top;
}
#mainContentBody-LC {
	width: 70%;
	padding: 18px 4%;

	&.fullwidth {
		width: 100%;
	}

	p {
		margin-bottom: 15px;
		@include TYPCOND(16px, normal, 300, 130%);
		@include fontDarkGray;
	}
	a {
		max-width: 100%;
		word-break: break-all;
		@include TYPCOND(16px, normal, 300, 130%);
		@include fontGold;
	}
	img {
		max-width: 100%;
		height: auto;
	}
	ul, ol {
		margin-bottom: 15px;
		@include TYPCOND(16px, normal, 300, 130%);
		@include fontDarkGray;
	}
	ul li {
		line-height: 160%;
		margin-left: 40px;
		list-style-type: disc;
	}
	ol li {
		line-height: 160%;
		margin-left: 40px;
		list-style-type: decimal;
	}
	strong {
		@include TYPCOND(16px, normal, 500, 130%);
	}
	em {
		@include TYPCOND(16px, italic, 500, 130%);
	}
	pre {
		@include MONO(13px, normal, 400, 130%);
	}
	hr {
		margin: 20px 0;
		border: none;
		border-top: 1px solid #ccc;
	}
	blockquote {
		padding-left: 2%;
		border-left: 3px solid #ccc6c2;
	}

	iframe {
		max-width: 100%;
	}
}
.bio-more + [data-readmore-toggle], .bio-more[data-readmore] {
	margin-top: 5px;
}
.gallery-item {
	a {
		width: 100%;

		img {
			max-width: 100%;
			height: auto;
			border: 1px solid #fff !important;
		}
	}
}
#mainContentBody-RC {
	width: 30%;
	padding-top: 68px;
	padding-right: 4%;
}
.alignleft, .alignright {
	margin-bottom: 15px;
}
.alignleft {
	float: left;
	margin-right: 20px;
}
.alignright {
	float: right;
	margin-left: 20px;
}
.alignright:after {
	display: block;
	clear: both;
	content: '';
}
.quickNav {
	width: 100%;
	margin-bottom: 20px;
}
.quickNav-Header {
	width: 100%;
	height: 30px;
	padding-left: 10px;
	text-transform: uppercase;
	color: #6d685f;
	border-bottom: 3px solid #cccac4;
	background-image: url(assets/img/tex/grayGrainWarm.png);
	@include TYPCOND(16px, normal, 400, 27px);
}
.quickNav-Content {
	padding: 5px 10px;
	border-right: 1px solid #cccac4;
	border-bottom: 1px solid #cccac4;
	border-left: 1px solid #cccac4;
	background-color: #fff;
}
.quickNav-Content a {
	@include TYPCOND(16px, normal, 400, 24px);
	@include fontGold;
}
.columnBanner {
	display: inline-block;
	width: 100%;
	height: 160px;
	margin-bottom: 20px;
}

/**NEWS PAGE**/
.post-meta {
	margin: 8px 0 10px;
	text-align: right;
	color: #7c7871;
	@include TYP(12px, italic, 400, 18px);
}
.post-meta a {
	font-style: normal;
	@include fontGold;
}
.more-link, .post-edit-link {
	text-transform: uppercase;
	@include TYPCOND(15px, normal, 400, 24px);
	@include fontGold;
}
.IAHR {
	font-size: 0;
	display: block;
	width: 100%;
	margin: 30px 0;
	text-align: center;

	&::before, &::after {
		position: relative;
		top: 5px;
		display: inline-block;
		height: 1px;
		content: '';
		vertical-align: top;
		background-color: #ccc6c2;
		@include calc('width', '50% - 30px');
	}
}
.IAHR-Event {
	font-size: 0;
	display: block;
	width: 25%;
	margin: 30px 0;
}
#mainContentBody-LC article:last-of-type .IAHR {
	display: none;
}
#mainContentBody-LC article.post-full:last-of-type .IAHR {
	display: block;
}
.IAHR-Event::before, .IAHR-Event::after {
	position: relative;
	top: 5px;
	display: inline-block;
	width: 36.3%;
	height: 1px;
	content: '';
	vertical-align: top;
	background-color: #ccc6c2;
}
.IAHR-Curly {
	display: inline-block;
	width: 39px;
	height: 10px;
	margin: 0 5px;
	background-image: url(assets/img/icons/IAHR-Curly.svg);
}
.news-Nav {
	margin-top: 35px;
	border-top: 1px solid #ccc6c2;
}
.news-Nav a {
	margin: 5px 0 15px;
	text-transform: uppercase;
	@include TYPCOND(15px, normal, 600, 24px);
	@include fontGold;
}
#mainContentBody-LC .wpcf7 {
	margin-top: 20px;

	.wpcf7-form {
		p {
			font-size: 16px;
			font-weight: 600;
			display: inline-block;
			width: 49%;
			margin-bottom: 15px;
			vertical-align: top;

			&:nth-child(odd) {
				margin-left: 2%;
			}
			&.full-width {
				display: block;
				width: 100%;
				margin: 0;
			}
			&.centered-width {
				display: block;
				width: 30%;
				margin: 0;
			}
		}
		input {
			font-size: 16px;
			width: 100%;
			margin: 5px 0 10px;
			padding: 4px;
			border: 1px solid #ccc;

			&[type='submit'] {
				margin-bottom: 6px;
				cursor: pointer;
				text-align: center;
				text-transform: uppercase;
				color: #fff;
				border: 0;
				background-color: $purple;
				@include TYPCOND(16px, normal, 600, 26px);
				@include transition(background-color 250ms ease-in-out);

				&:hover {
					text-decoration: none;
					background-color: #ca44e9;
				}
				&:active {
					text-decoration: none;
					background-color: #a725c5;
				}
			}
			&:focus {
				outline: 0;
			}
		}
		select {
			width: 50%;
			margin: 5px 0 10px;
			border: 1px solid #ccc;

			&:focus {
				outline: 0;
			}
		}
		textarea {
			width: 100%;
			max-width: 100%;
			margin: 5px 0 10px;
			padding: 4px;
			border: 1px solid #ccc;
			@include TYPCOND(15px, normal, 400, 120%);
			@include fontDarkGray;

			&:focus {
				outline: 0;
			}
		}
		span.wpcf7-not-valid-tip {
			font-size: 15px;
			font-style: italic;
			margin-bottom: 15px;
			color: #f60;
		}
		div.wpcf7-response-output {
			font-size: 12px;
			display: block;
			width: 100%;
			margin: 20px 0;
			padding: 10px 20px;
			text-align: center;
			color: #fff;
			@include TYPCOND(15px, normal, 400, 120%);
		}
		div.wpcf7-mail-sent-ok {
			border: none;
			background-color: #57db1f;
		}
		div.wpcf7-mail-sent-ng {
			border: none;
			background-color: #f00;
		}
		div.wpcf7-spam-blocked {
			border: none;
			background-color: #ffa500;
		}
		div.wpcf7-validation-errors {
			border: none;
			background-color: #fad948;
		}
	}
}
.justified-gallery > a > .caption, .justified-gallery > div > .caption {
	display: none !important;
}

/*MEDIA PAGE*/
.my_video_gallery.justified-gallery > .vyoutube.entry-visible {
	border: 1px solid #fff !important;
}

/*REV SLIDR */
.tp-caption {
	sup {
		font-size: 60%;
		position: relative;
		top: -7px;
		left: 2px;
	}
	.gold {
		font-size: 80%;
		display: block;
		margin-top: 8px !important;
		text-transform: uppercase;
		color: #f9de00;
	}
}

/*SHORTCODE STYLES*/
#mainContentBody {
	.panels {
		display: block;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.panel {
		display: inline-block;
		width: 33.33333333333333%;
		padding-top: 5px;
		vertical-align: top;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
	}
	.activePanel {
		background-color: #f3f3f3;
	}
	.panel a {
		position: relative;
		display: block;
		width: auto;
		height: auto;
		margin-bottom: 18px;
	}
	.panel img {
		display: block;
		margin: 0 auto;
	}
	#programs_panels .panel a {
		height: 65px;
	}
	#programs_panels img {
		position: absolute;
		top: 0;
		left: 0;
		clip: rect(0px, 173px, 65px, 0px);
	}
	#programs_panels img:hover {
		position: absolute;
		top: -65px;
		left: 0;
		clip: rect(65px, 173px, 130px, 0px);
	}
	.panel p {
		display: none;
		width: 300%;
		margin-top: -10px;
		margin-bottom: 0;
		padding: 10px 12px;
		border-bottom: 1px solid #fff;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		background-color: #f3f3f3;
	}
	.panel h3 {
		display: none;
		margin: 10px auto;
		text-align: center;
		text-decoration: underline;
		@include TYPCOND(17px, normal, 700, 120%);
	}
	.panel:nth-of-type(2) p {
		margin-left: -100%;
	}
	.panel:nth-of-type(3) p {
		margin-left: -200%;
	}
}

/** Events Calendar Updates */
#tribe-events-pg-template {
	font-size: 16px;
	padding: 18px 3.229974160206718%;

	#tribe-bar-form {
		display: none;
	}
	.tribe-events-page-title {
		@include TITLE(30px, normal, 600, 38px);
		@include fontDarkGray;
	}
	.tribe-events-nav-next, .tribe-events-nav-previous {
		display: inline-block;
		margin: 0;
		text-transform: uppercase;
		color: #fff;
		background-color: $purple;
		@include TYPCOND(15px, normal, 400, 100%);
		@include border-top-radius(3px);
		@include border-bottom-radius(3px);
		@include transition(background-color 250ms ease-in-out);

		&:hover {
			background-color: darken($purple, 10%);
		}
		a {
			display: block;
			width: 100%;
			height: 100%;
			padding: 8px 15px;
			color: #fff;

			&:hover {
				text-decoration: none;
			}
		}
	}
	.tribe-events-nav-next {
		float: right;
	}
	.tribe-events-nav-previous {
		float: left;
	}
	.tribe-events-ical {
		display: inline-block;
		margin: 0;
		padding: 8px 15px;
		text-transform: uppercase;
		color: #fff;
		background-color: #16ade6;
		@include TYPCOND(15px, normal, 400, 100%);
		@include border-top-radius(3px);
		@include border-bottom-radius(3px);
		@include transition(background-color 250ms ease-in-out);

		&:hover {
			text-decoration: none;
			background-color: darken(#16ade6, 10%);
		}
	}
	.tribe-events-gcal {
		display: inline-block;
		margin: 0 10px 0 0;
		padding: 8px 15px;
		text-transform: uppercase;
		color: #fff;
		background-color: #db3236;
		@include TYPCOND(15px, normal, 400, 100%);
		@include border-top-radius(3px);
		@include border-bottom-radius(3px);
		@include transition(background-color 250ms ease-in-out);

		&:hover {
			text-decoration: none;
			background-color: darken(#db3236, 10%);
		}
	}
	.tribe-events-back a {
		display: inline-block;
		margin: 0;
		padding: 8px 15px;
		text-transform: uppercase;
		color: #fff;
		background-color: $gold;
		@include TYPCOND(15px, normal, 400, 100%);
		@include border-top-radius(3px);
		@include border-bottom-radius(3px);
		@include transition(background-color 250ms ease-in-out);

		&:hover {
			text-decoration: none;
			background-color: darken($gold, 10%);
		}
	}
	h1.tribe-events-single-event-title {
		border-bottom: 1px solid $l-gray;
	}
	.tribe-events-schedule h2 {
		margin-bottom: 20px;
		padding-bottom: 10px;
		border-bottom: 2px solid $l-gray;
		@include TITLE(20px, normal, 600, 1.1);
	}
	.tribe-events-single .tribe_events {
		display: block;
		margin: 20px 0;
		padding: 15px 0;
		border-top: 1px solid $l-gray;
		border-bottom: 1px solid $l-gray;
	}
	.tribe-events-content {
		p {
			@include TYPCOND(15px, normal, 400, 120%);
			@include fontDarkGray;
		}
	}
	.tribe-events-cal-links {
		margin-top: 15px;
	}
	.tribe-events-meta-group {
		display: block;
		margin-top: 20px;
		background-color: #eee;
		@include border-top-radius(5px);
		@include border-bottom-radius(5px);

		h3 {
			padding: 10px 15px 8px;
			border-bottom: 1px solid #ccc;
		}
		dl {
			padding: 8px 15px 10px;
			@include TYPCOND(15px, normal, 400, 120%);
			@include fontDarkGray;

			a {
				@include fontGold;
			}
		}
		dd {
			margin-bottom: 10px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	.tribe-events-venue-map {
		margin: 20px 0 0 0;
	}
	#tribe-events-content .tribe-events-calendar {
		margin-top: 20px;
		border: 0;

		thead {
			th {
				padding-bottom: 10px;
				letter-spacing: normal;
				color: $d-gray;
				@include TYPCOND(13px, normal, 600, 100%);
			}
		}
		tbody {
			td {
				&.tribe-events-othermonth {
					background-color: lighten($tan, 15%);

					div[id*=tribe-events-daynum-] {
						color: lighten($tan, 15%);
					}
				}
				div[id*=tribe-events-daynum-] {
					@include TYPCOND(11px, normal, 600, 100%);
				}
				div[id*=tribe-events-event-] {
					padding: 6px 9px;

					h3 {
						font-size: inherit;
						line-height: normal;
						margin: 0;
						padding: 0;
					}
					a {
						color: $purple;
						@include TYPCOND(13px, normal, 600, 120%);
					}
				}
				&.tribe-events-present {
					background-color: lighten($gold, 35%);
				}
			}
		}
	}
	.tribe-events-notices {
		color: #fff;
		border: 0;
		background-color: #6bccfd;
		text-shadow: none;
		@include TYPCOND(15px, normal, 400, 100%);
	}
}

/* PRICE TABLES */
#mainContentBody .pricetable {
	display: block;
	width: 100%;
	margin-top: 40px;

	.package {
		display: inline-block;
		vertical-align: top;
		-webkit-border-top-left-radius: 3px;
		-moz-border-radius-topleft: 3px;
		border-top-left-radius: 3px;
		-webkit-border-top-right-radius: 3px;
		-moz-border-radius-topright: 3px;
		border-top-right-radius: 3px;
		background-color: #fff;
	}
	.standard {
		position: relative;
		z-index: 2;
		width: 50%;
		margin-bottom: 35px;
		padding: 0 3px;

		h2 {
			font-size: 24px;
			margin: 0;
			padding: 15px 0 12px;
			color: #fff;
			border-bottom: 1px solid darken(#c33ae7, 10%);
			background-color: #c33ae7;
			@include border-top-radius(3px);
		}
		h3 {
			margin: 0;
			padding: 20px 0 10px;
			color: #f9a12d;
			border-right: 1px solid #ccc6c2;
			border-left: 1px solid #ccc6c2;
		}
	}
	.large {
		position: relative;
		z-index: 4;
		top: -12px;
		width: 44%;

		h2 {
			font-size: 22px;
			margin: 0;
			padding: 8px 0;
			color: #fff;
			border-bottom: 1px solid #8613a0;
			-webkit-border-top-left-radius: 3px;
			-moz-border-radius-topleft: 3px;
			border-top-left-radius: 3px;
			-webkit-border-top-right-radius: 3px;
			-moz-border-radius-topright: 3px;
			border-top-right-radius: 3px;
			background-color: $purple;
		}
		h3 {
			font-size: 50px;
			margin: 0;
			padding: 30px 0 10px;
			color: $purple;
			border-right: 1px solid $purple;
			border-left: 1px solid $purple;
		}
		span, span strong {
			font-size: 17px;
			border-color: $purple;
		}
		span:last-of-type {
			border-color: $purple;
		}
		.learnmore, .inquire {
			border-color: $purple;
		}
		.learnmore span:last-of-type {
			font-size: 20px;
			line-height: 20px;
			border-color: #dfa004;
		}
		.inquire span:last-of-type {
			font-size: 20px;
			line-height: 20px;
			border-color: #5ea519;
		}
	}
	h2 {
		text-align: center;
		text-transform: uppercase;
		@include TYPCOND(18px, normal, 700, 120%);
	}
	h3 {
		font-size: 40px;
		font-weight: 600;
		text-align: center;

		em {
			display: block;
			margin-top: 20px;
		}
	}
	span {
		display: block;
		padding: 8px 5px;
		text-align: center;
		border-top: 1px dotted #ccc6c2;
		border-right: 1px solid #ccc6c2;
		border-left: 1px solid #ccc6c2;
		@include TYPCOND(15px, normal, 400, 120%);

		&:first-of-type {
			border-top: 0;
		}
		&:nth-child(odd) {
			background-color: #fafafa;
		}
		&:last-of-type {
			border-bottom: 1px dotted #ccc6c2;
		}
		strong {
			font-weight: 700;
		}
	}
	.packagemore {
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		display: none;
		width: 100%;
		height: 100%;

		.textwrap {
			position: absolute;
			top: 30%;
			left: 50%;
			display: block;
			width: 400px;
			margin-left: -200px;
			border: 1px solid #94928e;
			background-color: #fcfcfc;
			-webkit-box-shadow: 0 5px 2px rgba(0, 0, 0, .15);
			-moz-box-shadow: 0 5px 2px rgba(0, 0, 0, .15);
			box-shadow: 0 5px 2px rgba(0, 0, 0, .15);
		}
		p {
			display: block;
			margin-bottom: 0;
			padding: 8px 16px 0;
			@include TYPCOND(16px, normal, 300, 140%);
		}
		p:last-of-type {
			margin-bottom: 10px;
			padding: 8px 16px 3px;
		}
		.textwrap:before {
			display: block;
			width: 100%;
			height: 8px;
			content: '';
			background-color: #fad948;
		}
		.textwrap .close {
			position: absolute;
			top: -26px;
			right: -1px;
			display: block;
			width: 26px;
			height: 26px;
			padding: 2px;
			content: url(assets/img/icons/modalX.svg);
			cursor: pointer;
			border: 1px solid #94928e;
			border-bottom: none;
			background-color: #fad948;
		}
		.textwrap:hover {
			cursor: move;
		}
	}
	.learnmore {
		display: block;
		padding: 10px 12px 0;
		text-decoration: none;
		color: #fff;
		border-right: 1px solid #ccc6c2;
		border-left: 1px solid #ccc6c2;
		background-color: #fff;

		span {
			text-transform: uppercase;
			border: none;
			border: 1px solid #dfa004;
			background-color: #fad948;
			@include border-top-radius(3px);
			@include border-bottom-radius(3px);
			@include TYPCOND(18px, normal, 600, normal);
		}
	}
	.learnmore:hover span {
		border: 1px solid #c99b28;
		background-color: #eece44;
	}
	.inquire {
		display: block;
		padding: 10px 12px;
		text-decoration: none;
		color: #fff;
		border-right: 1px solid #ccc6c2;
		border-bottom: 1px solid #ccc6c2;
		border-left: 1px solid #ccc6c2;
		background-color: #fff;

		span {
			text-transform: uppercase;
			border: none;
			border: 1px solid #5ea519;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;
			background-color: #66bf17;
			-ms-border-radius: 3px;
			-o-border-radius: 3px;
			@include TYPCOND(18px, normal, 600, normal);
		}
	}
	.inquire:hover span {
		border: 1px solid #549713;
		background-color: #5eb115;
	}
}

/* TABLES */
.iamaTable {
	width: 100%;
	text-align: center;

	td {
		vertical-align: middle;
	}
	tr:nth-child(3n-2) td {
		padding: 5px;
		border: 1px solid #ddd;
		background-color: #fff;
	}
	tr:nth-child(3n-1) td {
		border: 1px solid #ddd;
		background-color: #fff;
	}
	tr:nth-child(3n) td {
		padding: 3px 0;
	}
	img {
		display: block;
		max-width: 96%;
		margin: 0 auto;
	}
}
#stateTable {
	border-spacing: 5px;
	border-collapse: separate;
}
.google-maps {
	position: relative;
	padding-bottom: 75%; // This is the aspect ratio
	height: 0;
	overflow: hidden;
}
.google-maps iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}

/** Gravity Forms */
#mainContentBody {
	.gform_wrapper {
		.gform_fields > li {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		.gsection_title {
			font-size: 20px;
			border-bottom: 1px solid #999;
			margin-top: 30px;
			padding-bottom: 8px;
		}

		.gfield_label {
			font-size: 16px;
			font-weight: 600;
			margin-top: 15px;
			display: block;
		}

		.ginput_complex {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			& > span {
				width: 48%;
			}
			& > span.ginput_full {
				width: 100%;
			}

			label {
				font-size: 15px;
				font-style: italic;
				margin-top: -5px;
				margin-bottom: 10px;
				display: block;
			}
		}
		.ginput_list {
			table {
				width: 100%;
			}
			thead {
				font-style: italic;
			}
			tbody {
				td {
					text-align: center;
					padding: 0 3px;
				}
				img {
					outline: none !important;
				}
			}
		}

		input[type=text],
		input[type=password],
		input[type=email],
		input[type=tel] {
			font-size: 16px;
			width: 100%;
			margin: 5px 0 10px;
			padding: 7px 10px;
			border: 1px solid #ccc;
			text-align: left;
			font-size: 14px;

			&:focus {
				outline: 0;
			}
		}
		ul.gfield_radio {
			li {
				list-style: none;
				margin: 0;
				padding: 5px 0;
			}
		}
		input[type=radio] {
			appearance: radio;
			cursor: pointer;

			& + label {
				cursor: pointer;
			}
			&:checked + label {
				font-weight: 600;
			}
		}
		input[type=checkbox] {
			appearance: checkbox;
			cursor: pointer;

			& + label {
				cursor: pointer;
			}
			&:checked + label {
				font-weight: 600;
			}
		}
		.selectize {
			&-input {
				.item {
					font-size: 14px;
				}
			}
			&-dropdown {
				.option {
					font-size: 14px;
				}
			}
		}
		.gfield.gform_validation_container,
		.gform_card_icon_container,
		.ginput_card_security_code_icon {
			display: none;
		}
		input[type='submit'] {
			cursor: pointer;
			text-align: center;
			text-transform: uppercase;
			color: #fff;
			border: 0;
			background-color: $purple;
			padding: 4px;
			width: 100%;
			@include TYPCOND(16px, normal, 600, 26px);
			@include transition(background-color 250ms ease-in-out);

			&:hover {
				text-decoration: none;
				background-color: #ca44e9;
			}
			&:active {
				text-decoration: none;
				background-color: #a725c5;
			}
		}
	}
	.gform_confirmation_message {
		display: block;
		font-size: 16px;
		color: #fff;
		background-color: #3f963f;
		padding: 10px 15px;
		text-align: center;
		@include TYPCOND(16px, normal, 600, 20px);
	}
}

/*===================================*\
RESPONSIVE
/*===================================*/
@media only screen and (max-width:1000px) {
	#wrapper {
		width: 96%;
	}
	.mainNav ul li {
		margin-right: 20px;
	}
}
@media only screen and (max-width:840px) {
	.resp {
		display: none !important;
	}
	.respb {
		display: block;
	}
	#rightColumn {
		width: 100%;
	}
	.hamburger, .respib {
		display: inline-block !important;
	}
	.mainLogo {
		width: 130px;
		margin-top: -50px;
		margin-bottom: 25px;
	}
	.hamburger.active {
		position: fixed;
		z-index: 999999;
		top: 20px;
		float: 0;
		@include calc('left', '50% - 15px');
	}
	.mainNav.active {
		position: fixed;
		z-index: 99999;
		top: 0;
		left: 0;
		display: block !important;
		width: 100%;
		height: 100%;
		padding-top: 75px;
		background-color: #000;

		ul {
			display: block;
			width: 100%;
			height: 100%;

			li {
				display: block;
				width: 100%;
				margin: 0;
				padding: 0;
				border-top: 1px solid rgba(255, 255, 255, .2);

				a {
					font-size: 20px;
					width: 100%;
					padding: 25px;
				}
				ul.sub-menu {
					position: static;

					a {
						font-size: 18px;
						padding: 15px 30px;
					}
				}
				&.menu-item-has-children {
					& > a {
						width: 80%;
						margin-left: 0;
					}
					&:hover::after {
						content: "–";
					}
					&::after {
						font-size: 20px;
						font-weight: 600;
						position: absolute;
						top: 21px;
						right: 7%;
						content: '+';
						color: $gold;
					}
				}
			}
			.mainNav-Opus a {
				background-position: 25px center;
				background-size: 65px auto;
			}
		}
	}
}
@media only screen and (max-width:530px) {
	#mainContentBody-LC, #mainContentBody-RC {
		width: 100%;
		padding-left: 4%;
	}
	#mainContentBody-RC {
		padding-top: 0;
	}
	#mainContentBody-LC .wpcf7 .wpcf7-form p {
		width: 100%;

		&:nth-child(odd) {
			margin-left: 0;
		}
	}
}
@media only screen and (max-width:460px) {
	#mainContentBody-LC img {
		display: block;
		margin: 0 auto 20px;

		&.alignleft, &.alignright {
			float: none;
		}
	}
	#mainContentBody .pricetable .standard {
		width: 100%;
	}
}
@import 'international';